import { types, Instance } from 'mobx-state-tree';
import { string, validatedString } from './fieldtypes/string';

import { validation } from './fieldtypes/validation';

export const validfield = types.model('validfield', {
  value: string,
  validation: validation,
});

export const baufinanzierung = types.model('zuhause', {
  frage1: validatedString,
  frage2: validatedString,
  frage3: validatedString,
  frage4: validatedString,
  frage5: validatedString,
  geburtsdatum: validatedString,
  anrede: validatedString,
  vorname: validatedString,
  nachname: validatedString,
  strasse: validatedString,
  plz: validatedString,
  ort: validatedString,
  telefon: validatedString,
  email: validatedString,
  receipientEmail: validatedString,
});

export type Ibaufinanzierung = Instance<typeof baufinanzierung>;

export const baufinanzierungScaffold = {
  receipientEmail: {
    value: null,
    validation: {
      required: false,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: '',
    },
  },
  frage1: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Was für ein Anlagetyp sind Sie?',
    },
  },
  frage2: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Wie viel möchten Sie monatlich sparen?',
    },
  },
  frage3: {
    value: null,
    validation: {
      required: false,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Wie viel möchten Sie einmalig anlegen?',
    },
  },
  frage4: {
    value: null,
    validation: {
      required: false,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Wann möchten Sie in Rente gehen?',
    },
  },
  frage5: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Sind Sie bereits Kunde bei ERGO oder DKV?',
    },
  },
  geburtsdatum: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 10,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Wann sind Sie geboren?',
    },
  },
  anrede: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte wählen',
    },
  },
  vorname: {
    value: null,
    validation: {
      required: true,
      maxLength: 40,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte geben Sie den Vornamen ein',
    },
  },
  nachname: {
    value: null,
    validation: {
      required: true,
      maxLength: 40,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte geben Sie den Nachnamen ein',
    },
  },
  strasse: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte geben Sie Straße und Hausnummer ein',
    },
  },
  plz: {
    value: null,
    validation: {
      required: false,
      maxLength: 5,
      minLength: 1,
      isNumeric: true,
      isEmail: false,
      validationMessage: 'Bitte geben Sie die Postleitzahl ein',
    },
  },
  ort: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte geben Sie den Ort ein',
    },
  },
  telefon: {
    value: null,
    validation: {
      required: false,
      maxLength: 40,
      minLength: 0,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte überprüfen Sie die Telefonnummer',
    },
  },
  email: {
    value: null,
    validation: {
      required: false,
      maxLength: 70,
      minLength: 0,
      isNumeric: false,
      isEmail: true,
      validationMessage: 'Bitte überprüfen Sie die E-Mail-Adresse',
    },
  },
};
