export const errorBoundary = {
  headline: 'Es ist ein Fehler aufgetreten',
  body: `
    <p>
      Sehr geehrte Kundin,
      <br />
      sehr geehrter Kunde,
    </p>
    <p>leider ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt nochmal.</p>
    <p>Vielen Dank für Ihr Verständnis!</p>`,
};
