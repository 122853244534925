export const timeout = {
  body: `
    <p>
      Sehr geehrte Kundin,<br>
      sehr geehrter Kunde,
    </p>
    <p>
      da Sie seit längerer Zeit keine Eingaben getätigt haben, ist die Sitzungszeit aus Sicherheitsgründen abgelaufen. Bitte beginnen Sie erneut mit Ihren Eingaben.<br>
      <br>
      Vielen Dank für Ihr Verständnis!
    </p>`,
  headline: 'Es ist ein Fehler aufgetreten',
  buttonLabel: 'Neu starten',
};
