import * as React from 'react';
import { observer } from 'mobx-react';

import { StoreContext } from '../StoreContext';
import { CampaignPage } from '../../pages/CampaignPage';
import { OAMPage } from '../../pages/OAMPage';
import { WuestenrotBasePage } from '../../pages/WuestenrotBasePage';

const pageMap: Map<string, React.ComponentType> = new Map();

pageMap.set('campaign', CampaignPage);
pageMap.set('oam', OAMPage);
pageMap.set('wuestenrot', WuestenrotBasePage);

export interface PageMapperProps {
  pageType: 'campaign' | 'oam' | 'wuestenrot';
}

@observer
export class PageMapper extends React.Component<PageMapperProps> {
  static contextType = StoreContext;
  //@ts-ignore
  context!: React.ContextType<typeof StoreContext>;

  render() {
    const Page = pageMap.get(this.props.pageType)!;
    return <Page />;
  }
}
