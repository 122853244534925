import * as React from 'react';

import classnames from 'classnames';

import { StoreContext } from '../StoreContext';
import CSS from './Picture.module.scss';
export interface PictureProps extends React.HTMLAttributes<HTMLPictureElement> {
  src: string;
  width?: string;
  height?: string;
  alt: string;
  sources?: { srcset: string; media: string; type: string }[];
  linkUrl?: string;
  linkTarget?: string;
  teaser?: JSX.Element | null;
  teaserLeft?: boolean | undefined;
  hideTeaserOnMobile?: boolean | undefined;
  priceTag?: JSX.Element | null;
  priceTagRight?: boolean | undefined;
  topRightTeaser?: JSX.Element | null;
  className?: string;
  loading?: 'eager' | 'lazy' | undefined;
}

export class Picture extends React.Component<PictureProps> {
  static contextType = StoreContext;
  //@ts-ignore
  context!: React.ContextType<typeof StoreContext>;

  render() {
    const {
      src,
      width,
      height,
      alt,
      sources,
      linkUrl,
      linkTarget,
      teaser,
      teaserLeft,
      hideTeaserOnMobile,
      priceTag,
      priceTagRight,
      topRightTeaser,
      loading,
      className: classNameFromProps,
    } = this.props;
    const target = linkTarget ? linkTarget : '_self';

    return linkUrl ? (
      <a href={linkUrl} target={target}>
        <picture className={classnames(classNameFromProps, CSS.picture)}>
          {sources &&
            sources.map(({ srcset, media, type }, index) => (
              <source key={index} srcSet={srcset} type={type} media={media}></source>
            ))}
          <img
            className="esc_container"
            src={this.context.config.context + '/' + src}
            alt={alt}
            width={width ? width : undefined}
            height={height ? height : undefined}
            loading={loading ? loading : undefined}
          ></img>
        </picture>
      </a>
    ) : (
      <>
        <picture className={classnames(classNameFromProps, CSS.picture)}>
          {sources &&
            sources.map(({ srcset, media }, index) => (
              <source key={index} srcSet={srcset} media={media}></source>
            ))}
          <img
            src={this.context.config.context + '/' + src}
            width={width ? width : undefined}
            height={height ? height : undefined}
            alt={alt}
            loading={loading ? loading : undefined}
          ></img>
        </picture>
        {priceTag && (
          <div className={classnames(CSS.priceTag, { [CSS.priceTagRight]: !!priceTagRight })}>
            {priceTag}
          </div>
        )}
        {teaser && (
          <div
            className={classnames(
              CSS.teaser,
              { [CSS.hideOnMobile]: !!hideTeaserOnMobile },
              { [CSS.teaserLeft]: !!teaserLeft }
            )}
          >
            {teaser}
          </div>
        )}
        {topRightTeaser && <div className={CSS.topRightTeaser}>{topRightTeaser}</div>}
      </>
    );
  }
}
