import { configure } from 'mobx';
import { flow, Instance, types, SnapshotOut } from 'mobx-state-tree';
import { axiosGet, TypedAxiosError, TypedAxiosResponse, AxiosReturn } from '../utils/authedAxios';
import merge from 'lodash/merge';

import { config, SIconfig } from './config';

import { ui, emptyUiScaffold } from './ui';
import { pages, emptyPagesScaffold } from './pages';
import { antrag, emptyAntragScaffold } from './antrag';

export const store = types
  .model('store', {
    config,
    ui,
    pages,
    antrag,
  })
  .actions((self) => {
    //@ts-ignore
    const load = flow(function* (url: string, isHandleError = true, showLoadingStatus = true) {
      if (showLoadingStatus) {
        self.ui.loadingStatus = 'LOADING';
      }
      const response: AxiosReturn<unknown> = yield axiosGet(url);
      if (response.type === 'error' && isHandleError) {
        self.ui.setError(
          response.message ||
            response.code ||
            // @ts-ignore
            (response.response && response.response.data && response.response.data.message) ||
            `Neither error message or statusCode are present. An network error propably occured for GET to ${url}`
        );
      }
      self.ui.loadingStatus = 'INACTIVE';
      return response;
    }) as storeFns['load'];
    return { load };
  });

export type storeFns = {
  load: <T = unknown>(
    url: string,
    isHandleError?: boolean,
    showLoadingStatus?: boolean
  ) => Promise<TypedAxiosError<T> | TypedAxiosResponse<T>>;
};

export type Istore = Instance<typeof store>;
export type SOstore = SnapshotOut<typeof store>;

const emptyStoreScaffold = {
  config: {},
  ui: emptyUiScaffold,
  pages: emptyPagesScaffold,
  antrag: emptyAntragScaffold,
};

export function createStore(config: SIconfig) {
  configure({ isolateGlobalState: true });
  const data = merge({}, emptyStoreScaffold, { config });
  return store.create(data);
}
