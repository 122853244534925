import { Iconfig } from '../../store/config';
import { Icampaign } from '../../store/pages/campaign';
import { getTrackingKategorie, getViewportBreakpoint, getDisplayedBrand } from './util';

export function trackOnLoad(config: Iconfig, campaign: Icampaign) {
  const omnitureKategorie = config.campaignName;
  if (!omnitureKategorie) {
    return;
  }

  const adobeTrackingKategorie = getTrackingKategorie(omnitureKategorie);

  if (adobeTrackingKategorie) {
    doAdobeTracking({
      event: 'PageLoaded',
      miscellaneous: {
        agency: {
          oenrNumber: config.pnr,
        },
      },
      page: {
        pageInfo: {
          pageName: campaign.info.tracking_pageName,
          //@ts-ignore
          issueDate: 'vertriebspartner-lp|' + process.env.REACT_APP_BUILDVERSION,
          //@ts-ignore
          sysEnv: process.env.REACT_APP_TRACKING_ENV,
        },
        category: {
          primaryCategory: 'ERGO-LP',
          pageType: 'Landingpage',
          pagePurpose: 'Sales',
        },
        attributes: {
          viewPort: getViewportBreakpoint(),
          displayedBrand: getDisplayedBrand(
            false,
            campaign.info.theme ? campaign.info.theme : null
          ),
        },
      },
      product: [
        {
          productInfo: {
            productName: adobeTrackingKategorie.adobeKategorie,
          },
          category: {
            primaryCategory: adobeTrackingKategorie.adobeProduktgruppe,
          },
          attributes: {
            productBranch: adobeTrackingKategorie.productBranch,
            productOwner: adobeTrackingKategorie.productOwner,
          },
        },
      ],
    });
  }
}

export function trackOnFormSubmit(config: Iconfig, conversionType: string) {
  const omnitureKategorie = config.campaignName;
  if (!omnitureKategorie) {
    return;
  }

  const adobeTrackingKategorie = getTrackingKategorie(omnitureKategorie);

  if (adobeTrackingKategorie) {
    let dataLayer = {
      event: 'PageLoaded',
      transaction: {
        attributes: {
          conversionType: conversionType ? conversionType : null,
        },
      },
    };
    doAdobeTracking(dataLayer);
  }
}

export function trackOnEvent(
  config: Iconfig,
  clickedElement: string,
  clickType: string | null,
  conversionType: string | null
) {
  const omnitureKategorie = config.campaignName;
  if (!omnitureKategorie) {
    return;
  }

  const adobeTrackingKategorie = getTrackingKategorie(omnitureKategorie);
  if (adobeTrackingKategorie) {
    let dataLayer = {
      event: 'GeneralClick',
      eventdetails: {
        clickedElement: clickedElement,
        clickType: clickType ? clickType : 'o',
      },
      transaction: {
        attributes: {
          conversionType: conversionType ? conversionType : null,
        },
      },
    };
    doAdobeTracking(dataLayer);
  }
}

function removeEmptyOrNull(obj: { [x: string]: any }) {
  //console.log('removeEmptyOrNull: ' + obj);
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] && typeof obj[k] === 'object' && removeEmptyOrNull(obj[k])) ||
      (!obj[k] && obj[k] !== undefined && delete obj[k])
  );
  return obj;
}

function doAdobeTracking(dataLayer: any) {
  //initialisierung data layer falls noch nicht geschehen
  // @ts-ignore
  window.appEventData = window.appEventData || [];
  //tracking aufruf
  //console.log(JSON.stringify(dataLayer, null, 2));
  // @ts-ignore
  window.appEventData.push(removeEmptyOrNull(dataLayer));
}
