import { types, Instance } from 'mobx-state-tree';
import { string, validatedString } from './fieldtypes/string';

import { validation } from './fieldtypes/validation';

export const validfield = types.model('validfield', {
  value: string,
  validation: validation,
});

export const investment = types.model('zuhause', {
  frage1: validatedString,
  anlagebetrag: validatedString,
  sparbetrag: validatedString,
  frage3: validatedString,
  frage4: validatedString,
  geburtsdatum: validatedString,
  anrede: validatedString,
  vorname: validatedString,
  nachname: validatedString,
  telefon: validatedString,
  email: validatedString,
  receipientEmail: validatedString,
});

export type Iinvestment = Instance<typeof investment>;

export const investmentScaffold = {
  receipientEmail: {
    value: null,
    validation: {
      required: false,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: '',
    },
  },
  frage1: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Wie wollen Sie Ihr Geld anlegen?',
    },
  },
  frage3: {
    value: null,
    validation: {
      required: false,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Welchen Anlagezweck verfolgen Sie?',
    },
  },
  frage4: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Sind Sie bereits Kunde bei ERGO oder DKV?',
    },
  },
  anlagebetrag: {
    value: null,
    validation: {
      required: false,
      maxLength: 6,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Wie hoch ist Ihr ungefährer Anlagebetrag ?',
    },
  },
  sparbetrag: {
    value: null,
    validation: {
      required: false,
      maxLength: 4,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Wie hoch ist Ihr ungefährer Sparbetrag?',
    },
  },
  geburtsdatum: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 10,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Wann sind Sie geboren?',
    },
  },
  versicherungsbeginn: {
    value: 'so bald wie möglich',
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Wann soll die Versicherung beginnen?',
    },
  },
  anrede: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte wählen',
    },
  },

  vorname: {
    value: null,
    validation: {
      required: true,
      maxLength: 40,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte geben Sie den Vornamen ein',
    },
  },
  nachname: {
    value: null,
    validation: {
      required: true,
      maxLength: 40,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte geben Sie den Nachnamen ein',
    },
  },
  telefon: {
    value: null,
    validation: {
      required: false,
      maxLength: 40,
      minLength: 0,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte überprüfen Sie die Telefonnummer',
    },
  },
  email: {
    value: null,
    validation: {
      required: false,
      maxLength: 70,
      minLength: 0,
      isNumeric: false,
      isEmail: true,
      validationMessage: 'Bitte überprüfen Sie die E-Mail-Adresse',
    },
  },
};
