import * as React from 'react';
import { TelephoneIcon } from '@eg/elements/Icons/TelephoneIcon';
import CSS from './AgentHeader.module.scss';

import { StoreContext } from '../StoreContext';
import { trackOnEvent } from '../../tracking';

export interface AgentHeaderProps {
  pictureUrl?: string;
  name: string;
  phone?: string | null;
}

export class AgentHeader extends React.Component<AgentHeaderProps> {
  static contextType = StoreContext;
  //@ts-ignore
  context!: React.ContextType<typeof StoreContext>;

  onClick = () => {
    const { config } = this.context;
    trackOnEvent(config, 'Header|Telefon', 'other', 'CtaPhoneStart');
  };

  render() {
    const { pictureUrl, name, phone } = this.props;

    return (
      <div className={CSS.wrapper}>
        <div className={CSS.image}>
          {pictureUrl && <img src={pictureUrl} className={CSS.imageFitCover} alt={name} />}
        </div>
        <div className={CSS.separator}></div>
        <div className={CSS.name}>
          <span>
            <strong>{name}</strong>
          </span>
          {phone && (
            <div className={CSS.phone}>
              <a href={'tel:' + phone} onClick={this.onClick}>
                <span className={CSS.icon}>
                  <TelephoneIcon width={24} height={24} />
                </span>
                <span className={CSS.text}>{phone}</span>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
