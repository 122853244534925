import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'picturefill';
// @ts-ignore
import objectFitImages from 'object-fit-images';

import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './resources/styles.scss';
import '@eg/elements/styles/dkv/styles.css';
import '@eg/elements/styles/ergo-one/styles.css';
import '@eg/elements/styles/core.css';

import { VermittlerReact } from './VermittlerReact';

function getShareImg(campaignName: string) {
  if (campaignName === 'zahn') {
    return 'https://www.ergo-agenturen.de/images/zahn/gettyimages-1132978391.jpg';
  } else if (campaignName === 'zahnzusatz') {
    return 'https://www.ergo-agenturen.de/images/zahnzusatz/zahnzusatz.jpg';
  } else if (campaignName === 'autoversicherung') {
    return 'https://www.ergo-agenturen.de/images/autoversicherung/autoversicherung.jpg';
  } else if (campaignName === 'schutzbrief') {
    return 'https://www.ergo-agenturen.de/images/schutzbrief/schutzbrief1.jpg';
  } else if (campaignName === 'rechtsschutz') {
    return 'https://www.ergo-agenturen.de/images/rechtsschutz/rechtsschutz.jpg';
  } else if (campaignName === 'dentalschutz') {
    return 'https://www.ergo-agenturen.de/images/dentalschutz/dentalschutz.jpg';
  } else if (campaignName === 'pkv') {
    return 'https://ergo-agenturen.de/images/pkv/pkv1.jpg';
  } else if (campaignName === 'zuhause-versichern') {
    return 'https://ergo-agenturen.de/images/pkv/zuhause-versichern1.jpg';
  } else if (campaignName === 'privatkunden') {
    return 'https://www.ergo-agenturen.de/images/privatkunden/privatkunden.jpg';
  } else if (campaignName === 'starkes-team') {
    return 'https://www.ergo-agenturen.de/images/starkes-team/starkes-team.jpg';
  } else if (campaignName === 'risiko-lebensversicherung') {
    return 'https://www.ergo-agenturen.de/images/risikolv/risikolv.jpg';
  } else if (campaignName === 'zez') {
    return 'https://www.ergo-agenturen.de/images/zahn/ZahnZV-ZEZ-Ergo_Patientin.jpg';
  } else if (campaignName === 'investment') {
    return 'https://www.ergo-agenturen.de/images/investment/investment1.jpg';
  } else if (campaignName === 'finanzplanung') {
    return 'https://www.ergo-agenturen.de/images/finanzplanung/finanzplanung1.jpg';
  } else if (campaignName === 'finanzplanung') {
    return 'https://www.ergo-agenturen.de/images/finanzplanung/finanzplanung1.jpg';
  } else if (campaignName === 'gewerbekunden') {
    return 'https://www.ergo-agenturen.de/images/gewerbekunden/gewerbekunden1.jpg';
  } else if (campaignName === 'krankenhaus') {
    return 'https://www.ergo-agenturen.de/images/krankenhaus/krankenhaus1.jpg';
  } else if (campaignName === 'zuhause-absichern') {
    return 'https://www.ergo-agenturen.de/images/zuhause-absichern/zuhause-versichern1.jpg';
  } else if (campaignName === 'reiseversicherung') {
    return 'https://www.ergo-agenturen.de/images/reiseversicherung/investment1.jpg';
  } else if (campaignName === 'unfallversicherung') {
    return 'https://www.ergo-agenturen.de/images/unfallversicherung/unfallversicherung1.jpg';
  } else if (campaignName === 'e-bike') {
    return 'https://www.ergo-agenturen.de/images/ebike/ebike1.jpg';
  } else if (campaignName === 'checkcare') {
    return 'https://www.ergo-agenturen.de/images/checkcare/checkcare1.jpg';
  } else if (campaignName === 'danv') {
    return 'https://www.ergo-agenturen.de/images/danv/danv1.jpg';
  } else if (campaignName === 'dfb-frauen') {
    return 'https://www.ergo-agenturen.de/images/dfb-frauen/dfb-frauen.jpg';
  } else if (campaignName === 'baufinanzierung') {
    return 'https://www.ergo-agenturen.de/images/baufinanzierung/baufinanzierung1.jpg';
  } else if (campaignName === 'wohngebaeude') {
    return 'https://www.ergo-agenturen.de/images/wohngebaeude/wohngebaeude1.jpg';
  } else if (campaignName === 'bu') {
    return 'https://www.ergo-agenturen.de/images/bu/bu1.jpg';
  } else if (campaignName === 'beamten') {
    return 'https://www.ergo-agenturen.de/images/beamtenversicherung/beamtenversicherung1.jpg';
  } else if (campaignName === 'altersvorsorge') {
    return 'https://www.ergo-agenturen.de/images/altersvorsorge/altersvorsorge1.jpg';
  } else if (campaignName === 'wuestenrot') {
    return 'https://www.ergo-agenturen.de/images/wuestenrot/wuestenrot1.jpg';
  } else if (campaignName === 'motorradversicherung') {
    return 'https://www.ergo-agenturen.de/images/motorrad/motorrad1.jpg';
  } else if (campaignName === 'sterbegeld') {
    return 'https://www.ergo-agenturen.de/images/sterbegeld/sterbegeld_1.jpg';
  } else {
    return 'https://ergo-agenturen.de/images/pkv/pkv1.jpg';
  }
}

function getShareTitle(campaignName: string) {
  if (campaignName === 'schutzbrief') {
    return 'ERGO Schutzbrief';
  } else if (campaignName === 'hausrat') {
    return 'ERGO Hausratversicherung';
  } else if (campaignName === 'autoversicherung') {
    return 'ERGO Autoversicherung';
  } else if (campaignName === 'rechtsschutz') {
    return 'ERGO Rechtsschutzversicherung';
  } else if (campaignName === 'privatkunden') {
    return 'ERGO Versicherung vor Ort (Privatkunden)';
  } else if (campaignName === 'starkes-team') {
    return 'ERGO Versicherung vor Ort (DFB Nationalmannschaft)';
  } else if (campaignName === 'risiko-lebensversicherung') {
    return 'ERGO Risiko-Lebensversicherung';
  } else if (campaignName === 'dentalschutz') {
    return 'ERGO Dental-Schutz & Dental-Vorsorge';
  } else if (campaignName === 'zahnzusatz') {
    return 'ERGO Zahnzusatzversicherung';
  } else if (campaignName === 'pkv') {
    return 'Private Krankenversicherung (PKV)';
  } else if (campaignName === 'zuhause-versichern') {
    return 'ERGO Hausrat- und Wohngebäude-Versicherung';
  } else if (campaignName === 'zez') {
    return 'ERGO Zahn-Ersatz-Sofort';
  } else if (campaignName === 'investment') {
    return 'ERGO Investmentfonds';
  } else if (campaignName === 'finanzplanung') {
    return 'ERGO Vorsorge & Investment';
  } else if (campaignName === 'gewerbekunden') {
    return 'ERGO Gewerbe-Versicherungen';
  } else if (campaignName === 'krankenhaus') {
    return 'ERGO Krankenhauszusatzversicherung';
  } else if (campaignName === 'zuhause-absichern') {
    return 'ERGO Hausrat- und Wohngebäude-Versicherung';
  } else if (campaignName === 'reiseversicherung') {
    return 'ERGO Reiseversicherungen';
  } else if (campaignName === 'unfallversicherung') {
    return 'ERGO Unfallversicherung';
  } else if (campaignName === 'e-bike') {
    return 'ERGO E-Bike Versicherung';
  } else if (campaignName === 'checkcare') {
    return 'ERGO check+care';
  } else if (campaignName === 'danv') {
    return 'DANV Deutsche Anwalt- und Notarversicherung';
  } else if (campaignName === 'dfbfrauen') {
    return 'ERGO Versicherung vor Ort (DFB Frauen)';
  } else if (campaignName === 'baufinanzierung') {
    return 'ERGO Bausparen und Finanzierung';
  } else if (campaignName === 'wohngebaeude') {
    return 'ERGO Wohngebäudeversicherung';
  } else if (campaignName === 'bu') {
    return 'ERGO Berufsunfähigkeit & Body-Protect';
  } else if (campaignName === 'beamten') {
    return 'ERGO Beamtenversicherungen';
  } else if (campaignName === 'altersvorsorge') {
    return 'ERGO Altersvorsorge';
  } else if (campaignName === 'wuestenrot') {
    return 'ERGO Bausparen und Finanzierung';
  } else if (campaignName === 'motorradversicherung') {
    return 'ERGO Motorradversicherung';
  } else if (campaignName === 'sterbegeld') {
    return 'ERGO Sterbegeldversicherung';
  } else {
    return 'Private Krankenversicherung (PKV)';
  }
}

function getShareDescription(campaignName: string) {
  if (campaignName === 'schutzbrief') {
    return 'Starke Leistungen bei Panne, Unfall oder Krankheit schon ab 59 € pro Jahr';
  } else if (campaignName === 'hausrat') {
    return 'Starker Schutz für Ihr Zuhause';
  } else if (campaignName === 'autoversicherung') {
    return 'Mit der ERGO Kfz-Versicherung erleben Sie im Ernstfall keine bösen Überraschungen';
  } else if (campaignName === 'rechtsschutz') {
    return 'Wir wollen, dass Sie Ihr gutes Recht bekommen.';
  } else if (campaignName === 'privatkunden') {
    return 'Kompetente Beratung von Ihrem ERGO Berater vor Ort für alle Versicherung von A Z.';
  } else if (campaignName === 'starkes-team') {
    return 'Ein starkes Team macht alles möglich.';
  } else if (campaignName === 'risiko-lebensversicherung') {
    return 'Vom günstigen Grundschutz bis zum Premiumschutz bleiben Sie mit der ERGO Risikolebensversicherung immer flexibel. Jetzt ganz einfach zu fairen Konditionen abschließen.';
  } else if (campaignName === 'dentalschutz') {
    return 'Wer zuletzt Lacht - ist bei ERGO versichert.';
  } else if (campaignName === 'pkv') {
    return 'Starke Leistungen beim Arzt, Zahnarzt und im Krankenhaus, die vertraglich garantiert sind.';
  } else if (campaignName === 'zahnzusatz') {
    return 'Leistungen wie ein Privat-Patient mit starker Kostenerstattung und besonderen Zusatzleistungen';
  } else if (campaignName === 'zuhause-versichern') {
    return 'Leistungsstarke Absicherung für Ihr Zuhause';
  } else if (campaignName === 'zez') {
    return 'Zahnzusatzversicherung mit Sofortschutz ohne Wartezeit';
  } else if (campaignName === 'investment') {
    return 'Beunruhigt wegen der Rentenlücke und Inflation? Wir haben was dagegen!';
  } else if (campaignName === 'finanzplanung') {
    return 'Investieren Sie jetzt in Ihre Zukunft! Bevor die Inflation Ihr Erspartes immer weiter aufzehrt.';
  } else if (campaignName === 'gewerbekunden') {
    return 'Schützen Sie Ihren Erfolg mit unseren gewerblichen Versicherungslösungen.';
  } else if (campaignName === 'krankenhaus') {
    return 'Mit einer Krankenhauszusatzversicherung schaffen Sie als gesetzlich Versicherter die Voraussetzung für die freie Arzt- und Krankenhauswahl – und das bundesweit';
  } else if (campaignName === 'zuhause-absichern') {
    return 'Leistungsstarke Absicherung für Ihr Zuhause';
  } else if (campaignName === 'reiseversicherung') {
    return 'Die besten Reiseversicherungen von ERGO, ERV und DKV für Sie.';
  } else if (campaignName === 'unfallversicherung') {
    return 'Sicherheit für ein aktives Leben.';
  } else if (campaignName === 'e-bike') {
    return 'Immer im Gepäck: Die ERGO E-Bike Versicherung';
  } else if (campaignName === 'checkcare') {
    return 'Meine Gesundheit - meine Entscheidung!';
  } else if (campaignName === 'danv') {
    return 'Weil wir wissen, worauf es Ihnen ankommt!';
  } else if (campaignName === 'dfb-frauen') {
    return 'Mit den DFB-Frauen ist das Fansein einfach. Und mit uns das Versichern.';
  } else if (campaignName === 'baufinanzierung') {
    return 'Jetzt schnell noch günstige Zinsen sichern';
  } else if (campaignName === 'wohngebaeude') {
    return 'Der Schutz für Ihr Zuhause';
  } else if (campaignName === 'bu') {
    return 'Genießen Sie besten Service und attraktive Beiträge';
  } else if (campaignName === 'beamten') {
    return 'Attraktive Konditionen für alle Lebensbereiche';
  } else if (campaignName === 'altersvorsorge') {
    return '';
  } else if (campaignName === 'wuestenrot') {
    return 'ERGO und Wüstenrot - Starke Partner für Bausparen und Immobilienfinanzierung';
  } else if (campaignName === 'motorradversicherung') {
    return 'Mit der günstigen Motorradversicherung von ERGO sind Sie sicher unterwegs: ✓ Haftpflicht ✓ Vollkasko ✓ Teilkasko -> Jetzt online berechnen auch für Motorroller, Roller, Leichtkraftrad!';
  } else if (campaignName === 'motorradversicherung') {
    return 'Das Beste, was Sie hinterlassen können: alles geregelt zu haben';
  } else {
    return 'Kompetente Beratung von Ihrem ERGO Berater vor Ort für alle Versicherung von A Z.';
  }
}

function addMetaTag(name: string, content: string) {
  var meta = document.createElement('meta');
  meta.setAttribute('property', name);
  meta.content = content;
  document.getElementsByTagName('head')[0].appendChild(meta);
}

function setTitle(campaignName: string) {
  if (campaignName === 'schutzbrief') {
    document.title = 'ERGO Schutzbrief';
  } else if (campaignName === 'hausrat') {
    document.title = 'ERGO Hausratversicherung | Starker Schutz für Ihr Zuhause';
  } else if (campaignName === 'autoversicherung') {
    document.title = 'ERGO Autoversicherung | Einfach. Schneller. Für Sie da.';
  } else if (campaignName === 'rechtsschutz') {
    document.title = 'ERGO Rechtsschutzversicherung | Top Leistung und bestens beraten';
  } else if (campaignName === 'privatkunden') {
    document.title = 'ERGO Versicherung vor Ort (Privatkunden)';
  } else if (campaignName === 'starkes-team') {
    document.title = 'ERGO Versicherung vor Ort (DFB Nationalmannschaft)';
  } else if (campaignName === 'risiko-lebensversicherung') {
    document.title = 'ERGO Risiko-Lebensversicherung';
  } else if (campaignName === 'dentalschutz') {
    document.title = 'ERGO Dental-Schutz & Dental-Vorsorge';
  } else if (campaignName === 'zahnzusatz') {
    document.title =
      'Zahnzusatzversicherung der DKV | Top Leistung und bestens vor Ort beraten | ERGO Versicherung';
  } else if (campaignName === 'pkv') {
    document.title = 'ERGO Private Krankenversicherung (PKV)';
  } else if (campaignName === 'zuhause-versichern') {
    document.title = 'ERGO Wohngebäude-Versicherung';
  } else if (campaignName === 'zez') {
    document.title = 'ERGO Zahn-Ersatz-Sofort';
  } else if (campaignName === 'investment') {
    document.title = 'ERGO Investmentfonds';
  } else if (campaignName === 'finanzplanung') {
    document.title = 'ERGO Vorsorge & Investment';
  } else if (campaignName === 'gewerbekunden') {
    document.title = 'ERGO Gewerbe-Versicherungen';
  } else if (campaignName === 'krankenhaus') {
    document.title = 'ERGO Krankenhauszusatzversicherung';
  } else if (campaignName === 'zuhause-absichern') {
    document.title = 'ERGO Wohngebäude-Versicherung';
  } else if (campaignName === 'reiseversicherung') {
    document.title = 'ERGO Reiseversicherungen';
  } else if (campaignName === 'unfallversicherung') {
    document.title = 'ERGO Unfallversicherung';
  } else if (campaignName === 'e-bike') {
    document.title = 'ERGO E-Bike Versicherung';
  } else if (campaignName === 'checkcare') {
    document.title = 'ERGO check+care';
  } else if (campaignName === 'danv') {
    document.title = 'DANV Deutsche Anwalt- und Notarversicherung';
  } else if (campaignName === 'dfb-frauen') {
    document.title = 'ERGO Versicherung vor Ort (DFB Frauen)';
  } else if (campaignName === 'baufinanzierung') {
    document.title = 'ERGO Bausparen und Finanzierung';
  } else if (campaignName === 'wohngebaeude') {
    document.title = 'ERGO Wohngebäudeversicherung';
  } else if (campaignName === 'bu') {
    document.title = 'ERGO Berufsunfähigkeit & Body-Protect';
  } else if (campaignName === 'beamten') {
    document.title = 'ERGO Beamtenversicherungen';
  } else if (campaignName === 'altersvorsorge') {
    document.title = 'ERGO Altersvorsorge';
  } else if (campaignName === 'wuestenrot') {
    document.title = 'ERGO Bausparen und Finanzierung';
  } else if (campaignName === 'motorradversicherung') {
    document.title = 'ERGO Motorradversicherung';
  } else if (campaignName === 'sterbegeld') {
    document.title = 'ERGO Sterbegeldversicherung';
  } else {
    document.title = 'ERGO Versicherung vor Ort (Privatkunden)';
  }
}

var path = window.location.pathname.split('/');
const campaignName = path && path.length >= 2 ? path[1] : '';
const pnr = path && path.length >= 3 ? path[2] : '';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let variant = urlParams.get('v');
variant = variant ? variant : '';
let cid = urlParams.get('cid');
cid = cid ? cid : '';

addMetaTag('og:title', getShareTitle(campaignName));
addMetaTag('og:description', getShareDescription(campaignName));
addMetaTag('og:image', getShareImg(campaignName));

ReactDOM.render(
  <Router basename="">
    <Suspense fallback={<div>Seiteninhalt laden ...</div>}>
      <Switch>
        <Route path="/zahnzusatz">
          <VermittlerReact
            campaignName="zahnzusatz"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/zahn">
          <VermittlerReact
            campaignName="zahn"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/zez">
          <VermittlerReact
            campaignName="zez"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/rechtsschutz">
          <VermittlerReact
            campaignName="rechtsschutz"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/privatkunden">
          <VermittlerReact
            campaignName="privatkunden"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/starkes-team">
          <VermittlerReact
            campaignName="starkesteam"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/risiko-lebensversicherung">
          <VermittlerReact
            campaignName="risikolv"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/dentalschutz">
          <VermittlerReact
            campaignName="dentalschutz"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/hausrat">
          <VermittlerReact
            campaignName="hausrat"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/autoversicherung">
          <VermittlerReact
            campaignName="autoversicherung"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/schutzbrief">
          <VermittlerReact
            campaignName="schutzbrief"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/pkv">
          <VermittlerReact
            campaignName="pkv"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/zuhause-versichern">
          <VermittlerReact
            campaignName="zuhauseversichern"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/investment">
          <VermittlerReact
            campaignName="investment"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/finanzplanung">
          <VermittlerReact
            campaignName="finanzplanung"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/gewerbekunden">
          <VermittlerReact
            campaignName="gewerbekunden"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/krankenhaus">
          <VermittlerReact
            campaignName="krankenhaus"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/krankenhaus-tarifrechner">
          <VermittlerReact
            campaignName="krankenhaus"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="oam"
          />
        </Route>
        <Route path="/zuhause-absichern">
          <VermittlerReact
            campaignName="zuhauseabsichern"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/reiseversicherung">
          <VermittlerReact
            campaignName="reiseversicherung"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/unfallversicherung">
          <VermittlerReact
            campaignName="unfallversicherung"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/e-bike">
          <VermittlerReact
            campaignName="ebike"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/checkcare">
          <VermittlerReact
            campaignName="checkcare"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/danv">
          <VermittlerReact
            campaignName="danv"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/dfb-frauen">
          <VermittlerReact
            campaignName="dfbfrauen"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/baufinanzierung">
          <VermittlerReact
            campaignName="baufinanzierung"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/wohngebaeude">
          <VermittlerReact
            campaignName="wohngebaeude"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/bu">
          <VermittlerReact
            campaignName="bu"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/beamten">
          <VermittlerReact
            campaignName="beamten"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/wuestenrot">
          <VermittlerReact
            campaignName="wuestenrot"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="wuestenrot"
          />
        </Route>
        <Route path="/altersvorsorge">
          <VermittlerReact
            campaignName="altersvorsorge"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/motorradversicherung">
          <VermittlerReact
            campaignName="motorradversicherung"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/sterbegeld">
          <VermittlerReact
            campaignName="sterbegeld"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
        <Route path="/">
          <VermittlerReact
            campaignName="privatkunden"
            pnr={pnr}
            variant={variant}
            context=""
            cid={cid}
            pageType="campaign"
          />
        </Route>
      </Switch>
    </Suspense>
  </Router>,
  document.getElementById('root')
);

//@ts-ignore
document.createElement('picture');
objectFitImages(null, { watchMQ: true });
setTitle(campaignName);
