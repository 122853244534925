import { Instance, types } from 'mobx-state-tree';

export const ui = types
  .model('ui', {
    error: types.maybe(types.string),
    loadingStatus: types.optional(types.enumeration(['INACTIVE', 'LOADING']), 'INACTIVE'),
    action: types.maybe(types.string),
  })
  .actions((self) => {
    return {
      setError(error?: unknown) {
        self.error =
          typeof error === 'undefined'
            ? undefined
            : typeof error === 'string'
            ? error
            : String(error);
      },

      setLoadingStatus(status: typeof self['loadingStatus']) {
        self.loadingStatus = status;
      },

      setAction(action?: string) {
        self.action = action;
      },
    };
  });

export type Iui = Instance<typeof ui>;

export const emptyUiScaffold = {};
