import axios, { AxiosResponse, AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

export const serviceDomain = process.env.REACT_APP_VERMITTLER_SERVICE_DOMAIN!;

const axiosBaseConfiguration = {
  auth: {
    username: process.env.REACT_APP_VERMITTLER_SERVICE_USER!,
    password: process.env.REACT_APP_VERMITTLER_SERVICE_PASSWORD!,
  },
  baseURL: `${serviceDomain}/`,
  headers: { 'oas-uid': '', 'oas-oenr': '', 'oas-pnr': '', 'oas-coopGrpVnr': '' },
};

export let authedAxios = axios.create(axiosBaseConfiguration);
enableAxiosRetry(authedAxios);

export function authedAxiosUpdateUid(uid: string) {
  axiosBaseConfiguration.headers['oas-uid'] = uid;
  authedAxios = axios.create(axiosBaseConfiguration);
  enableAxiosRetry(authedAxios);
}

export function authedAxiosUpdateVermittler(oeNr: string, pnr: string) {
  axiosBaseConfiguration.headers['oas-oenr'] = oeNr;
  axiosBaseConfiguration.headers['oas-pnr'] = pnr;
  authedAxios = axios.create(axiosBaseConfiguration);
  enableAxiosRetry(authedAxios);
}

export function authedAxiosUpdateCooperation(coopGrpVnr: string) {
  axiosBaseConfiguration.headers['oas-coopGrpVnr'] = coopGrpVnr;
  authedAxios = axios.create(axiosBaseConfiguration);
  enableAxiosRetry(authedAxios);
}

export interface TypedAxiosResponse<T = unknown> extends AxiosResponse<T> {
  type: 'success';
}

export interface TypedAxiosError<T = unknown> extends AxiosError<T> {
  type: 'error';
}

export type AxiosReturn<T = unknown> = TypedAxiosResponse<T> | TypedAxiosError<T>;

export async function axiosGet<T = unknown>(
  url: string,
  config?: AxiosRequestConfig
): Promise<TypedAxiosError<T> | TypedAxiosResponse<T>> {
  // const axiosInstance = await axios.get(`http://localhost:4000${url}`);
  let returnValue: TypedAxiosError<T> | TypedAxiosResponse<T>;

  try {
    let response = await authedAxios.get(url, config);
    returnValue = { type: 'success', ...response };
  } catch (e) {
    //@ts-ignore
    returnValue = { type: 'error', ...e };
    console.error('await axiosGet', e);
  }
  return returnValue;
}

export async function axiosPost<T>(
  url: string,
  payload: any = {},
  config?: AxiosRequestConfig
): Promise<TypedAxiosError<T> | TypedAxiosResponse<T>> {
  let returnValue: TypedAxiosError<T> | TypedAxiosResponse<T>;

  try {
    let response = await authedAxios.post(url, payload, config);
    returnValue = { type: 'success', ...response };
  } catch (e) {
    //@ts-ignore
    returnValue = { type: 'error', ...e };

    //@ts-ignore
    if (e.response && e.response.status !== 400) {
      //@ts-ignore
      console.log('await axiosPost', e.response);
    }
  }
  return returnValue;
}

function enableAxiosRetry(axiosInstance: AxiosInstance) {
  axiosRetry(axiosInstance, {
    retries: 4,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => {
      return isNetworkError(error);
    },
  });
}

function isNetworkError(err: any) {
  return !!err.isAxiosError && !err.response;
}
