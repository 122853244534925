import * as React from 'react';
import classnames from 'classnames';

import CSS from './HTML.module.scss';

interface HTMLProps extends React.HTMLAttributes<HTMLElement> {
  content: string;
  tag?: string;
}

export class HTML extends React.Component<HTMLProps> {
  static defaultProps = {
    tag: 'span',
  };

  render() {
    const { className: classNameFromProps, tag } = this.props;
    return React.createElement(tag!, {
      className: classnames(classNameFromProps, CSS.html, 'esc_generated-content'),
      dangerouslySetInnerHTML: { __html: this.props.content },
    });
  }
}
