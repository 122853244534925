import { errorBoundary } from './error-boundary';
import { info } from './info';
import { period } from './period';
import { switchableDate } from './switchableDate';
import { timeout } from './timeout';

export const components = {
  errorBoundary,
  info,
  period,
  switchableDate,
  timeout,
};
