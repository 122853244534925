import { Instance, types } from 'mobx-state-tree';

export const validation = types.model('validation', {
  required: types.boolean,
  maxLength: types.number,
  minLength: types.number,
  isNumeric: types.boolean,
  isEmail: types.boolean,
  validationMessage: types.string,
});

export type Ivalidation = Instance<typeof validation>;
