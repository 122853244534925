import * as React from 'react';

import { PropInjector } from '../../utils/PropInjector';

export type OarContextType = {};

export const { Consumer: OarContextConumser, Provider: OarContextProvider } = React.createContext(
  {} as OarContextType
);

export const withOarContext: PropInjector<Partial<OarContextType>> = function (Component) {
  return (props) => (
    <OarContextConumser>
      {({ ...context }) => <Component {...context} {...props} />}
    </OarContextConumser>
  );
};
