import {
  applySnapshot,
  flow,
  getRoot,
  getSnapshot,
  types,
  Instance,
  SnapshotIn,
} from 'mobx-state-tree';

import { campaign, emptyCampaignScaffold } from './campaign';

import { trackOnLoad } from '../../tracking';
import { AxiosReturn } from '../../utils/authedAxios';

export enum PageNames {
  campaign = 'campaign',
}

export const pages = types
  .model('pages', {
    campaign,
  })
  .actions((self) => {
    return {
      //@ts-ignore
      load: flow(function* (
        pageName: keyof typeof PageNames,
        method: 'get' | 'post' = 'get',
        getParameters: object = {}
      ) {
        const store = getRoot(self);

        //@ts-ignore
        let url = '/v1/campaign/' + store.config.campaignName + '/' + store.config.pnr;

        let firstParameter = url.indexOf('?') > 0 ? false : true;
        for (let key in getParameters) {
          //@ts-ignore
          url += (firstParameter ? '?' : '&') + key + '=' + getParameters[key];
          firstParameter = false;
        }

        let response: AxiosReturn;
        if (method === 'get') {
          //@ts-ignore
          response = yield store.load(url);
        } else {
          //@ts-ignore
          response = yield store.post(url, getSnapshot(store.antrag));
        }

        if (response.type === 'error') {
          //@ts-ignore
          store.ui.setError(response);
          return response;
        }
        // @ts-ignore
        applySnapshot(self[pageName], response.data);
        // this touches every element to trigger it's creation
        //        (self[pageName].content.elements as Ielements[]).forEach((e) => e);
        // @ts-ignore
        self[pageName].isLoaded = true;

        //@ts-ignore
        trackOnLoad(store.config, self.campaign);
        return response;
      }),
    };
  });

export type SIpages = SnapshotIn<typeof pages>;
export type Ipages = Instance<typeof pages>;

export const emptyPagesScaffold = {
  campaign: emptyCampaignScaffold,
};
