export function convertMSTPathToObjectPath(path: string) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, antrag, firstPart, ...otherPathParts] = path.split('/');

  let objectPath = firstPart;
  for (const part of otherPathParts) {
    if (part.match(/^[CF]?\d.*/)) {
      // Numbers and Healthquestion-Textnumbers which start with C or F like F10000 have to be handled as index
      objectPath = objectPath + `[${part}]`;
    } else {
      objectPath = objectPath + '.' + part;
    }
  }
  return objectPath;
}
