import {
  types,
  ModelPrimitive,
  IAnyType,
  SnapshotIn,
  getPath,
  IAnyStateTreeNode,
} from 'mobx-state-tree';
import { convertMSTPathToObjectPath } from '../../../utils/convertMSTPathToObjectPath';
import { IfieldTypes } from '.';
import { Iboolean } from './boolean';
import { Istring } from './string';
import { Inumber } from './number';

export type fieldTypeReturn<T> = {
  path?: string;
  value: T;
  set: (value: T) => void;
  validationMessage?: string;
};

export function fieldtypeFactory<T extends ModelPrimitive | IAnyType>(type: T) {
  return (
    types
      .model({
        value: type,
        errorMessage: types.maybe(types.string),
      })
      .views((self) => ({
        get path() {
          const path = getPath(self as IAnyStateTreeNode);
          return convertMSTPathToObjectPath(path);
        },
        get validationMessage(): string | undefined {
          return self.errorMessage;
        },
      }))
      .actions((self) => ({
        set(value: SnapshotIn<T>) {
          self.value = value;
        },
        setValidationMessage(value: string) {
          self.errorMessage = value;
        },
      }))
      .postProcessSnapshot((snapshot) => snapshot.value)
      // @ts-ignore Don't know how to tell typescript that this is okay
      .preProcessSnapshot((value: SnapshotIn<T>) => ({ value }))
  );
}

export function isFieldTypeBoolean(field: IfieldTypes): field is Iboolean {
  return typeof field.value === 'boolean';
}

export function isFieldTypeString(field: IfieldTypes): field is Istring {
  return typeof field.value === 'string';
}
export function isFieldTypeNumber(field: IfieldTypes): field is Inumber {
  return typeof field.value === 'number';
}
