import { observable } from 'mobx';
import { IfieldTypes } from './fieldtypes';
import { isArray } from '../../utils/isArray';

export function isFieldType(testSubject: any): testSubject is IfieldTypes {
  return typeof testSubject === 'object' && 'value' in testSubject && 'path' in testSubject && 'set' in testSubject;
}

export type getResponseType =
  | {
      type: 'array';
      field: any[];
    }
  | {
      type: 'fieldModel';
      field: IfieldTypes;
      value: IfieldTypes['value'];
    }
  | {
      type: 'primitive';
      field: string | number | boolean;
      value: string | number | boolean;
    };

export function createGetResponse(field: IfieldTypes | IfieldTypes[] | string | number | boolean): getResponseType {
  if (typeof field === 'string' || typeof field === 'number' || typeof field === 'boolean') {
    return {
      type: 'primitive',
      field,
      get value() {
        return field as string | number | boolean;
      },
    };
  } else if (isFieldType(field)) {
    return observable({
      type: 'fieldModel',
      field,
      get value() {
        return this.field.value;
      },
    });
  } else if (isArray<IfieldTypes>(field)) {
    return {
      type: 'array',
      field,
    };
  }
  throw new Error(`Unknown Type for ${field}`);
}
