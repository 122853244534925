import React, { Suspense } from 'react';
import { observer } from 'mobx-react';

import classnames from 'classnames';

import { AgentHeader } from '../../components/AgentHeader';
import { Layout } from '../../components/Layout';
import { Picture } from '../../components/Picture';
import { StoreContext } from '../../components/StoreContext';

import CSS from './CampaignPage.module.scss';

const RechtsschutzPage = React.lazy(() => import('../RechtsschutzPage/RechtsschutzPage'));
const ZEZCampaignPage = React.lazy(() => import('../ZEZPage/ZEZPage'));
const ZahnCampaignPage = React.lazy(() => import('../ZahnPage/ZahnPage'));
const SchutzbriefPage = React.lazy(() => import('../SchutzbriefPage/SchutzbriefPage'));
const PkvPage = React.lazy(() => import('../PkvPage/PkvPage'));
const ZuhauseVersichernPage = React.lazy(
  () => import('../ZuhauseVersichernPage/ZuhauseVersichernPage')
);
const ZahnzusatzPage = React.lazy(() => import('../ZahnzusatzPage/ZahnzusatzPage'));
const DentalschutzPage = React.lazy(() => import('../DentalschutzPage/DentalschutzPage'));
const HausratPage = React.lazy(() => import('../HausratPage/HausratPage'));
const AutoversicherungPage = React.lazy(
  () => import('../AutoversicherungPage/AutoversicherungPage')
);
const PrivatkundenPage = React.lazy(() => import('../PrivatkundenPage/PrivatkundenPage'));
const StarkesTeamPage = React.lazy(() => import('../StarkesTeamPage/StarkesTeam'));
const RisikoLebensversicherungPage = React.lazy(
  () => import('../RisikoLebensversicherungPage/RisikoLebensversicherungPage')
);
const InvestmentPage = React.lazy(() => import('../InvestmentPage/Investment'));
const FinanzplanungPage = React.lazy(() => import('../FinanzplanungPage/FinanzplanungPage'));
const GewerbekundenPage = React.lazy(() => import('../GewerbekundenPage/GewerbekundenPage'));
const KrankenhausPage = React.lazy(() => import('../KrankenhausPage/KrankenhausPage'));
const ZuhauseAbsichernPage = React.lazy(() => import('../ZuhauseAbsichernPage/ZuhauseAbsichern'));
const ReiseversicherungPage = React.lazy(
  () => import('../ReiseversicherungPage/Reiseversicherung')
);
const UnfallversicherungPage = React.lazy(
  () => import('../UnfallversicherungPage/UnfallversicherungPage')
);
const EBikePage = React.lazy(() => import('../EBikePage/EBikePage'));
const CheckCarePage = React.lazy(() => import('../CheckCarePage/CheckCarePage'));
const DANVPage = React.lazy(() => import('../DANVPage/DANVPage'));
const DFBFrauenPage = React.lazy(() => import('../DFBFrauenPage/DFBFrauen'));
const BaufinanzierungPage = React.lazy(() => import('../BaufinanzierungPage/BaufinanzierungPage'));
const WohngebaeudePage = React.lazy(() => import('../WohngebaeudePage/WohngebaeudePage'));
const BUPage = React.lazy(() => import('../BUPage/BUPage'));
const BeamtenversicherungPage = React.lazy(
  () => import('../BeamtenversicherungPage/BeamtenversicherungPage')
);
const AltersvorsorgePage = React.lazy(() => import('../AltersvorsorgePage/AltersvorsorgePage'));
const MotorradPage = React.lazy(() => import('../MotorradPage/MotorradPage'));
const SterbegeldPage = React.lazy(() => import('../SterbegeldPage/SterbegeldPage'));

@observer
export class CampaignPage extends React.Component {
  static contextType = StoreContext;
  //@ts-ignore
  context!: React.ContextType<typeof StoreContext>;

  componentDidMount() {
    const { pages } = this.context;
    if (!pages.campaign.isLoaded) {
      if (this.context.config.variant) {
        pages.load('campaign', 'get', { v: this.context.config.variant });
      } else {
        pages.load('campaign');
      }
    }

    var docWidth = document.documentElement.offsetWidth;

    [].forEach.call(document.querySelectorAll('*'), function (el: any) {
      if (el && el.offsetWidth > docWidth) {
        console.log(el);
      }
    });
  }

  Header = () => {
    const { campaign } = this.context.pages;
    const { agent } = campaign;

    return (
      <>
        {agent ? (
          <>
            <div className={CSS.logo}>
              {campaign.info.ab_experiment === 'danv' ? (
                <Picture src="images/DANVLOGO.png" width="250px" alt="DANV" />
              ) : campaign.info.theme === 'dkv' ? (
                <>
                  <Picture src="images/logo_dkv.svg" width="198px" height="47px" alt="DKV Logo" />
                  <div className={CSS.subline}>
                    <span className={CSS['city-prefix']}>
                      {agent.anredeschluessel && agent.anredeschluessel === '2'
                        ? 'Ihre DKV Beraterin aus '
                        : 'Ihr DKV Berater aus '}
                    </span>
                    <span>{agent.city}</span>
                  </div>
                </>
              ) : (
                <Picture src="images/ergo_logo.svg" width="260px" alt="ERGO" />
              )}
            </div>
            <AgentHeader
              name={agent.name}
              phone={agent.phone}
              pictureUrl={agent.portraitImageUrl}
            />
          </>
        ) : (
          <>
            <div className={CSS.logo}>
              <Picture src="images/ergo_logo.svg" width="360px" alt="ERGO Logo" />
            </div>
          </>
        )}
      </>
    );
  };

  Main = () => {
    const { config } = this.context;

    if (config.campaignName === 'zahn') {
      return (
        <Suspense fallback={<div></div>}>
          <ZahnCampaignPage />
        </Suspense>
      );
    } else if (config.campaignName === 'hausrat') {
      return (
        <Suspense fallback={<div></div>}>
          <HausratPage />
        </Suspense>
      );
    } else if (config.campaignName === 'autoversicherung') {
      return (
        <Suspense fallback={<div></div>}>
          <AutoversicherungPage />
        </Suspense>
      );
    } else if (config.campaignName === 'dentalschutz') {
      return (
        <Suspense fallback={<div></div>}>
          <DentalschutzPage />
        </Suspense>
      );
    } else if (config.campaignName === 'privatkunden') {
      return (
        <Suspense fallback={<div></div>}>
          <PrivatkundenPage />
        </Suspense>
      );
    } else if (config.campaignName === 'starkesteam') {
      return (
        <Suspense fallback={<div></div>}>
          <StarkesTeamPage />
        </Suspense>
      );
    } else if (config.campaignName === 'risikolv') {
      return (
        <Suspense fallback={<div></div>}>
          <RisikoLebensversicherungPage />
        </Suspense>
      );
    } else if (config.campaignName === 'rechtsschutz') {
      return (
        <Suspense fallback={<div></div>}>
          <RechtsschutzPage />
        </Suspense>
      );
    } else if (config.campaignName === 'schutzbrief') {
      return (
        <Suspense fallback={<div></div>}>
          <SchutzbriefPage />
        </Suspense>
      );
    } else if (config.campaignName === 'pkv') {
      return (
        <Suspense fallback={<div></div>}>
          <PkvPage />
        </Suspense>
      );
    } else if (config.campaignName === 'zuhauseversichern') {
      return (
        <Suspense fallback={<div></div>}>
          <ZuhauseVersichernPage />
        </Suspense>
      );
    } else if (config.campaignName === 'zahnzusatz') {
      return (
        <Suspense fallback={<div></div>}>
          <ZahnzusatzPage />
        </Suspense>
      );
    } else if (config.campaignName === 'zez') {
      return (
        <Suspense fallback={<div></div>}>
          <ZEZCampaignPage />
        </Suspense>
      );
    } else if (config.campaignName === 'investment') {
      return (
        <Suspense fallback={<div></div>}>
          <InvestmentPage />
        </Suspense>
      );
    } else if (config.campaignName === 'finanzplanung') {
      return (
        <Suspense fallback={<div></div>}>
          <FinanzplanungPage />
        </Suspense>
      );
    } else if (config.campaignName === 'gewerbekunden') {
      return (
        <Suspense fallback={<div></div>}>
          <GewerbekundenPage />
        </Suspense>
      );
    } else if (config.campaignName === 'krankenhaus') {
      return (
        <Suspense fallback={<div></div>}>
          <KrankenhausPage />
        </Suspense>
      );
    } else if (config.campaignName === 'zuhauseabsichern') {
      return (
        <Suspense fallback={<div></div>}>
          <ZuhauseAbsichernPage />
        </Suspense>
      );
    } else if (config.campaignName === 'reiseversicherung') {
      return (
        <Suspense fallback={<div></div>}>
          <ReiseversicherungPage />
        </Suspense>
      );
    } else if (config.campaignName === 'unfallversicherung') {
      return (
        <Suspense fallback={<div></div>}>
          <UnfallversicherungPage />
        </Suspense>
      );
    } else if (config.campaignName === 'ebike') {
      return (
        <Suspense fallback={<div></div>}>
          <EBikePage />
        </Suspense>
      );
    } else if (config.campaignName === 'checkcare') {
      return (
        <Suspense fallback={<div></div>}>
          <CheckCarePage />
        </Suspense>
      );
    } else if (config.campaignName === 'danv') {
      return (
        <Suspense fallback={<div></div>}>
          <DANVPage />
        </Suspense>
      );
    } else if (config.campaignName === 'dfbfrauen') {
      return (
        <Suspense fallback={<div></div>}>
          <DFBFrauenPage />
        </Suspense>
      );
    } else if (config.campaignName === 'baufinanzierung') {
      return (
        <Suspense fallback={<div></div>}>
          <BaufinanzierungPage />
        </Suspense>
      );
    } else if (config.campaignName === 'wohngebaeude') {
      return (
        <Suspense fallback={<div></div>}>
          <WohngebaeudePage />
        </Suspense>
      );
    } else if (config.campaignName === 'bu') {
      return (
        <Suspense fallback={<div></div>}>
          <BUPage />
        </Suspense>
      );
    } else if (config.campaignName === 'beamten') {
      return (
        <Suspense fallback={<div></div>}>
          <BeamtenversicherungPage />
        </Suspense>
      );
    } else if (config.campaignName === 'altersvorsorge') {
      return (
        <Suspense fallback={<div></div>}>
          <AltersvorsorgePage />
        </Suspense>
      );
    } else if (config.campaignName === 'motorradversicherung') {
      return (
        <Suspense fallback={<div></div>}>
          <MotorradPage />
        </Suspense>
      );
    } else if (config.campaignName === 'sterbegeld') {
      return (
        <Suspense fallback={<div></div>}>
          <SterbegeldPage />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={<div></div>}>
          <PrivatkundenPage />
        </Suspense>
      );
    }
  };

  Footer = () => {
    const { campaign } = this.context.pages;
    return (
      <>
        <div className={CSS.footer}>
          <div className={CSS.links}>
            <div className={CSS.linkBar}>
              {campaign.info.agent_url && (
                <ul>
                  <li>
                    <a href={'https://' + campaign.info.agent_url + '/de/Service/Datenschutz'}>
                      Datenschutz
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        'https://' +
                        campaign.info.agent_url +
                        '/de/Agentur/Erstkontaktinformationen'
                      }
                    >
                      Erstkontaktinformationen
                    </a>
                  </li>
                  <li>
                    {campaign.info.agent_url && (
                      <a href={'https://' + campaign.info.agent_url + '/de/Service/Anbieter'}>
                        Anbieter
                      </a>
                    )}
                  </li>
                </ul>
              )}
              {!campaign.info.agent_url && (
                <ul>
                  <li>
                    <a href="https://www.ergo.de/de/Service/Datenschutz">Datenschutz</a>
                  </li>
                  <li>
                    <a href="https://www.ergo.de/de/Service/Anbieter">Impressum/Erstinformation</a>
                  </li>
                </ul>
              )}
            </div>
          </div>

          <div className={CSS.info}>
            <div className={classnames(CSS.endorsment)}>
              <ul>
                <li>
                  <img
                    src={this.context.config.context + '/images/logo_ergo.svg'}
                    height="16px"
                    alt="ERGO Logo"
                  />
                </li>
                <li>
                  <img
                    src={this.context.config.context + '/images/logo_dkv.svg'}
                    height="16px"
                    alt="DKV Logo"
                  />
                </li>
              </ul>
            </div>
            <div className={classnames(CSS.copyright)}>
              <strong>Rechtlicher Hinweis:</strong> Dies ist eine Information der ERGO Versicherung
              AG und dient Werbezwecken. <br />© {new Date().getFullYear()} ERGO Versicherung AG |
              Alle Rechte vorbehalten.
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { campaign } = this.context.pages;

    if (!campaign.isLoaded) {
      return null;
    }

    return <Layout header={<this.Header />} main={<this.Main />} footer={<this.Footer />} />;
  }
}
