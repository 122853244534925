import * as React from 'react';
import { TelephoneIcon } from '@eg/elements/Icons/TelephoneIcon';
import CSS from './PhoneHeader.module.scss';

import { StoreContext } from '../../../components/StoreContext';
import { trackOnEvent } from '../../../tracking';

export interface AgentHeaderProps {
  phone: string;
  phoneDisplay: string;
  text: string;
}

export class PhoneHeader extends React.Component<AgentHeaderProps> {
  //@ts-ignore
  context!: React.ContextType<typeof StoreContext>;

  onClick = () => {
    const { config } = this.context;
    trackOnEvent(config, 'Header|Telefon', 'other', 'CtaPhoneStart');
  };

  render() {
    const { phone, phoneDisplay, text } = this.props;

    return (
      <div className={CSS.wrapper}>
        {phone && (
          <>
            <div className={CSS.phone}>
              <a href={'tel:' + phone} onClick={this.onClick}>
                <span className={CSS.icon}>
                  <TelephoneIcon width={24} height={24} />
                </span>
                <span>{phoneDisplay}</span>
              </a>
            </div>
            <div className={CSS.text}>
              <span>{text}</span>
            </div>
          </>
        )}
      </div>
    );
  }
}
