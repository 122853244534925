import { types, Instance } from 'mobx-state-tree';

import { agent } from './agent';

export const _basePage = types
  .model('page', {
    isLoaded: false,
    info: types.model({
      campaignName: types.string,
      ab_experiment: types.maybeNull(types.string),
      ab_variant: types.maybeNull(types.string),
      tracking_pageName: types.string,
      agent_url: types.maybeNull(types.string),
      theme: types.maybe(
        types.union(
          types.literal('ergodirekt'),
          types.literal('ergo'),
          types.literal('das'),
          types.literal('dkv'),
          types.literal('ergo-one')
        )
      ),
    }),
    agent: types.maybe(agent),
  })
  .actions((self) => ({
    setIsLoaded(value: typeof self.isLoaded) {
      self.isLoaded = value;
    },
  }));

export type IbasePage = Instance<typeof _basePage>;
export type Iagent = Instance<typeof agent>;
