import * as React from 'react';
import { observer } from 'mobx-react';

import { HTML } from '../HTML';
import { StoreContext } from '../StoreContext';

import { dict } from '../../dict';

/* https://reactjs.org/docs/error-boundaries.html */

@observer
export class ErrorBoundary extends React.Component {
  static contextType = StoreContext;
  //@ts-ignore
  context!: React.ContextType<typeof StoreContext>;

  state = { error: undefined };

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: any) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }
  }

  componentDidUpdate() {
    const error = this.state.error || this.context.ui.error;
    if (error) {
      console.log(JSON.stringify(error, undefined, 2));
    }
  }

  render() {
    const error = this.state.error || this.context.ui.error;
    if (error) {
      console.error(JSON.stringify(error, undefined, 2));
      return (
        <div>
          <h1>{dict.components.errorBoundary.headline}</h1>
          <HTML content={dict.components.errorBoundary.body} />
        </div>
      );
    }
    return this.props.children;
  }
}
