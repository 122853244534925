import React, { Suspense } from 'react';
import { observer } from 'mobx-react';

import classnames from 'classnames';

import { AgentHeader } from '../../components/AgentHeader';
import { Layout } from '../../components/Layout';
import { Picture } from '../../components/Picture';
import { StoreContext } from '../../components/StoreContext';

import CSS from './OAMPage.module.scss';

const KrankenhausOAMPage = React.lazy(() => import('../KrankenhausOAMPage/KrankenhausOAMPage'));

@observer
export class OAMPage extends React.Component {
  static contextType = StoreContext;
  //@ts-ignore
  context!: React.ContextType<typeof StoreContext>;

  componentDidMount() {
    const { pages } = this.context;
    if (!pages.campaign.isLoaded) {
      if (this.context.config.variant) {
        pages.load('campaign', 'get', { v: this.context.config.variant });
      } else {
        pages.load('campaign');
      }
    }
  }

  Header = () => {
    const { campaign } = this.context.pages;
    const { agent } = campaign;

    return (
      <>
        {agent ? (
          <>
            <div className={CSS.logo}>
              {campaign.info.theme === 'dkv' ? (
                <>
                  <Picture src="images/logo_dkv.svg" width="198px" height="47px" alt="DKV Logo" />
                  <div className={CSS.subline}>
                    <span className={CSS['city-prefix']}>
                      {agent.anredeschluessel && agent.anredeschluessel === '2'
                        ? 'Ihre DKV Beraterin aus '
                        : 'Ihr DKV Berater aus '}
                    </span>
                    <span>{agent.city}</span>
                  </div>
                </>
              ) : (
                <Picture src="images/ergo_logo.svg.svg" width="360px" alt="ERGO" />
              )}
            </div>
            <AgentHeader
              name={agent.name}
              phone={agent.phone}
              pictureUrl={agent.portraitImageUrl}
            />
          </>
        ) : (
          <>
            <div className={CSS.logo}>
              <Picture src="images/ergo_logo.svg" width="360px" alt="ERGO Logo" />
            </div>
          </>
        )}
      </>
    );
  };

  Main = () => {
    const { config } = this.context;

    if (config.campaignName === 'krankenhaus') {
      return (
        <Suspense fallback={<div></div>}>
          <KrankenhausOAMPage />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={<div></div>}>
          <KrankenhausOAMPage />
        </Suspense>
      );
    }
  };

  Footer = () => {
    const { campaign } = this.context.pages;
    return (
      <>
        <div className={CSS.footer}>
          <div className={CSS.links}>
            <div className={CSS.linkBar}>
              {campaign.info.agent_url && (
                <ul>
                  <li>
                    <a href={'https://' + campaign.info.agent_url + '/de/Service/Datenschutz'}>
                      Datenschutz
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        'https://' +
                        campaign.info.agent_url +
                        '/de/Agentur/Erstkontaktinformationen'
                      }
                    >
                      Erstkontaktinformationen
                    </a>
                  </li>
                  <li>
                    {campaign.info.agent_url && (
                      <a href={'https://' + campaign.info.agent_url + '/de/Service/Anbieter'}>
                        Anbieter
                      </a>
                    )}
                  </li>
                </ul>
              )}
              {!campaign.info.agent_url && (
                <ul>
                  <li>
                    <a href="https://www.ergo.de/de/Service/Datenschutz">Datenschutz</a>
                  </li>
                  <li>
                    <a href="https://www.ergo.de/de/Service/Anbieter">Impressum/Erstinformation</a>
                  </li>
                </ul>
              )}
            </div>
          </div>

          <div className={CSS.info}>
            <div className={classnames(CSS.endorsment)}>
              <ul>
                <li>
                  <img
                    src={this.context.config.context + '/images/logo_ergo.svg'}
                    height="16px"
                    alt="ERGO Logo"
                  />
                </li>
                <li>
                  <img
                    src={this.context.config.context + '/images/logo_dkv.svg'}
                    height="16px"
                    alt="DKV Logo"
                  />
                </li>
              </ul>
            </div>
            <div className={classnames(CSS.copyright)}>
              <strong>Rechtlicher Hinweis:</strong> Dies ist eine Information der ERGO Versicherung
              AG und dient Werbezwecken. <br />© {new Date().getFullYear()} ERGO Versicherung AG |
              Alle Rechte vorbehalten.
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { campaign } = this.context.pages;

    if (!campaign.isLoaded) {
      return null;
    }

    return <Layout header={<this.Header />} main={<this.Main />} footer={<this.Footer />} />;
  }
}
