import React from 'react';
import { Istore } from '../../store';
import { PropInjector } from '../../utils/PropInjector';

const contextDefaultvalue = {} as Istore;

export const StoreContext = React.createContext(contextDefaultvalue);

export const { Consumer: StoreConsumer, Provider: StoreProvider } = StoreContext;

export const withStore: PropInjector<{ store?: Istore }> = function (Component) {
  return (props) => (
    <StoreConsumer>
      {(store) => {
        return <Component store={store} {...props} />;
      }}
    </StoreConsumer>
  );
};
