import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Provider as ErgoThemeProvider } from '@eg/elements/Provider';
import { OarContextProvider } from './components/OarContext/OarContext';
import { StoreProvider } from './components/StoreContext';
import { ErrorBoundary } from './components/ErrorBoundary';
import { createStore, Istore } from './store';
import { PageMapper } from './components/PageMapper';

import './snippets/snippets.scss';

export interface VermittlerReactProps {
  pnr: string;
  campaignName: string;
  variant: string;
  context: string;
  cid: string;
  pageType: 'campaign' | 'oam' | 'wuestenrot';
}

@observer
export class VermittlerReact extends React.Component<VermittlerReactProps> {
  @observable
  store: Istore;

  constructor(props: VermittlerReactProps) {
    super(props);
    const { ...config } = props;

    this.store = createStore({
      ...config,
      serviceDomain: process.env.REACT_APP_VERMITTLER_SERVICE_DOMAIN!,
    });
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <StoreProvider value={this.store}>
        <OarContextProvider value={{}}>
          <ErgoThemeProvider
            theme={this.store.pages.campaign.info.theme}
            data-oar
            data-experiment={this.store.pages.campaign.info.ab_experiment}
            id="VRARootContainer"
            style={{ marginBottom: '8em' }}
          >
            <ErrorBoundary>
              <PageMapper pageType={this.props.pageType} />
            </ErrorBoundary>
          </ErgoThemeProvider>
        </OarContextProvider>
      </StoreProvider>
    );
  }
}
