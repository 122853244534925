import { types, Instance } from 'mobx-state-tree';
import { Themes } from '../config';

import { _basePage } from './_base-page';

export const campaign = types.compose(
  types.model('campaign', {}),
  _basePage
);

export const emptyCampaignScaffold = {
  info: { campaignName: '', tracking_pageName: '', ab_fallback: false, theme: Themes.dkv },
  content: {},
};

export type Icampaign = Instance<typeof campaign>;
