import { Iconfig } from '../store/config';
import { Icampaign } from '../store/pages/campaign';
import {
  trackOnLoad as adobeTrackOnload,
  trackOnEvent as adobeTrackOnEvent,
  trackOnFormSubmit as adobeTrackOnFormSubmit,
} from './adobe';

export function trackOnLoad(config: Iconfig, campaign: Icampaign) {
  try {
    adobeTrackOnload(config, campaign);
  } catch (e) {
    console.error(e);
  }
}

export function trackOnFormSubmit(config: Iconfig, conversionType: string) {
  try {
    adobeTrackOnFormSubmit(config, conversionType);
  } catch (e) {
    console.error(e);
  }
}

export function trackOnEvent(
  config: Iconfig,
  clickedElement: string,
  clickType: string | null,
  conversionType: string | null
) {
  try {
    adobeTrackOnEvent(config, clickedElement, clickType, conversionType);
  } catch (e) {
    console.error(e);
  }
}
