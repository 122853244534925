import { types } from 'mobx-state-tree';

import { fieldtypeFactory, fieldTypeReturn } from './fieldtypeFactory';

import { validation } from './validation';

export const string = fieldtypeFactory(types.optional(types.string, '', [null, undefined]));
export type Istring = fieldTypeReturn<string>;

export const validatedString = types.model('validfield', {
  value: string,
  validation: validation,
});
