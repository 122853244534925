import { types, Instance } from 'mobx-state-tree';
import { string, validatedString } from './fieldtypes/string';

import { validation } from './fieldtypes/validation';

export const validfield = types.model('validfield', {
  value: string,
  validation: validation,
});

export const kontakt = types.model('kontakt', {
  frage: validatedString,
  frage1: types.maybe(validatedString),
  frage2: types.maybe(validatedString),
  frage3: types.maybe(validatedString),
  frage4: types.maybe(validatedString),
  frage5: types.maybe(validatedString),
  anrede: validatedString,
  vorname: validatedString,
  nachname: validatedString,
  telefon: validatedString,
  email: validatedString,
  receipientEmail: validatedString,
});

export type Ikontakt = Instance<typeof kontakt>;

export const kontaktScaffold = {
  receipientEmail: {
    value: null,
    validation: {
      required: false,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: '',
    },
  },
  frage: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte geben Sie Ihr Anliegen ein',
    },
  },
  anrede: {
    value: null,
    validation: {
      required: true,
      maxLength: 2048,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte wählen',
    },
  },
  vorname: {
    value: null,
    validation: {
      required: true,
      maxLength: 40,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte geben Sie den Vornamen ein',
    },
  },
  nachname: {
    value: null,
    validation: {
      required: true,
      maxLength: 40,
      minLength: 1,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte geben Sie den Nachnamen ein',
    },
  },
  telefon: {
    value: null,
    validation: {
      required: false,
      maxLength: 40,
      minLength: 0,
      isNumeric: false,
      isEmail: false,
      validationMessage: 'Bitte überprüfen Sie die Telefonnummer',
    },
  },
  email: {
    value: null,
    validation: {
      required: true,
      maxLength: 70,
      minLength: 5,
      isNumeric: false,
      isEmail: true,
      validationMessage: 'Bitte überprüfen Sie die E-Mail-Adresse',
    },
  },
};
