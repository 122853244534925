import { Instance, types, SnapshotIn } from 'mobx-state-tree';

export enum Themes {
  dkv = 'dkv',
  'ergo-one' = 'ergo-one',
  'ergo' = 'ergo',
}

export const config = types.model('config', {
  context: types.string,
  pnr: types.string,
  campaignName: types.string,
  variant: types.string,
  serviceDomain: types.string,
  cid: types.string,
});

export type Iconfig = Instance<typeof config>;
export type SIconfig = SnapshotIn<typeof config>;
