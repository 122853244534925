import {
  types,
  Instance,
  addMiddleware,
  getRoot,
  SnapshotOut,
  IDisposer,
  SnapshotIn,
} from 'mobx-state-tree';
import _get from 'lodash/get';

import { createGetResponse } from './createGetReponse';
import { IfieldTypes } from './fieldtypes';
import { Ivalidation } from './fieldtypes/validation';

import { kontakt, kontaktScaffold } from './kontakt';
import { zez, zezScaffold } from './zez';
import { schutzbrief, schutzbriefScaffold } from './schutzbrief';
import { pkv, pkvScaffold } from './pkv';
import { zuhause, zuhauseScaffold } from './zuhause';
import { zahnzusatz, zahnzusatzScaffold } from './zahnzusatz';
import { rechtsschutz, rechtsschutzScaffold } from './rechtsschutz';
import { dentalschutz, dentalschutzScaffold } from './dentalschutz';
import { hausrat, hausratScaffold } from './hausrat';
import { autoversicherung, autoversicherungScaffold } from './autoversicherung';
import { privatkunden, privatkundenScaffold } from './privatkunden';
import { investment, investmentScaffold } from './investment';
import { finanzplanung, finanzplanungScaffold } from './finanzplanung';
import {
  risikolebensversicherung,
  risikolebensversicherungScaffold,
} from './risikolebensversicherung';
import { gewerbekunden, gewerbekundenScaffold } from './gewerbekunden';
import { krankenhaus, krankenhausScaffold } from './krankenhaus';
import { reiseversicherung, reiseversicherungScaffold } from './reiseversicherung';
import { unfallversicherung, unfallversicherungScaffold } from './unfallversicherung';
import { ebike, ebikeScaffold } from './ebike';
import { checkcare, checkcareScaffold } from './checkcare';
import { danv, danvScaffold } from './danv';
import { baufinanzierung, baufinanzierungScaffold } from './baufinanzierung';
import { bu, buScaffold } from './bu';
import { beamtenversicherung, beamtenversicherungScaffold } from './beamtenversicherung';
import { wuestenrot, wuestenrotScaffold } from './wuestenrot';
import { altersvorsorge, altersvorsorgeScaffold } from './altersvorsorge';
import { motorradversicherung, motorradversicherungScaffold } from './motorradversicherung';
import { sterbegeld, sterbegeldScaffold } from './sterbegeld';

export const antrag = types
  .model('antrag', {
    kontakt: kontakt,
    zez: zez,
    schutzbrief: schutzbrief,
    pkv: pkv,
    zuhause: zuhause,
    zahnzusatz: zahnzusatz,
    rechtsschutz: rechtsschutz,
    dentalschutz: dentalschutz,
    hausrat: hausrat,
    autoversicherung: autoversicherung,
    privatkunden: privatkunden,
    risikolebensversicherung: risikolebensversicherung,
    investment: investment,
    finanzplanung: finanzplanung,
    gewerbekunden: gewerbekunden,
    krankenhaus: krankenhaus,
    reiseversicherung: reiseversicherung,
    unfallversicherung: unfallversicherung,
    ebike: ebike,
    checkcare: checkcare,
    danv: danv,
    baufinanzierung: baufinanzierung,
    bu: bu,
    beamtenversicherung: beamtenversicherung,
    wuestenrot: wuestenrot,
    altersvorsorge: altersvorsorge,
    motorradversicherung: motorradversicherung,
    sterbegeld: sterbegeld,
  })
  .volatile((self) => ({
    isDirty: false,
  }))
  .actions((self) => ({
    setIsDirty(value: boolean) {
      self.isDirty = value;
    },
  }))
  .views((self) => {
    function get(path: string): ReturnType<typeof createGetResponse> {
      let field: IfieldTypes | string | number | boolean | undefined = _get(self, path + '.value');
      if (typeof field === 'undefined') {
        throw new Error(`Failed to resolve path: ${path}`);
      } else {
        return createGetResponse(field);
      }
    }

    function getValidation(path: string): Ivalidation {
      let validation: Ivalidation = _get(self, path + '.validation');
      if (typeof validation === 'undefined') {
        throw new Error(`Failed to resolve path: ${path}`);
      } else {
        return validation;
      }
    }

    return {
      get,
      getValidation,
    };
  })
  .actions((self) => {
    let middleWareDisposers: IDisposer[] = [];

    function trackIsDirty() {
      middleWareDisposers.push(
        addMiddleware(self, (call, next) => {
          if (call.name !== 'setIsDirty') {
            self.setIsDirty(true);
          }
          next(call);
        })
      );
    }

    return {
      afterCreate() {
        trackIsDirty();

        const store = getRoot(self);
        middleWareDisposers.push(
          //@ts-ignore
          addMiddleware(store.ui, function (call, next) {
            next(call);
          })
        );
      },

      beforeDestroy() {
        middleWareDisposers.forEach((disposer) => disposer());
      },
    };
  });

export const emptyAntragScaffold = {
  kontakt: kontaktScaffold,
  zez: zezScaffold,
  schutzbrief: schutzbriefScaffold,
  pkv: pkvScaffold,
  zuhause: zuhauseScaffold,
  zahnzusatz: zahnzusatzScaffold,
  rechtsschutz: rechtsschutzScaffold,
  dentalschutz: dentalschutzScaffold,
  hausrat: hausratScaffold,
  autoversicherung: autoversicherungScaffold,
  privatkunden: privatkundenScaffold,
  risikolebensversicherung: risikolebensversicherungScaffold,
  investment: investmentScaffold,
  finanzplanung: finanzplanungScaffold,
  gewerbekunden: gewerbekundenScaffold,
  krankenhaus: krankenhausScaffold,
  reiseversicherung: reiseversicherungScaffold,
  unfallversicherung: unfallversicherungScaffold,
  ebike: ebikeScaffold,
  checkcare: checkcareScaffold,
  danv: danvScaffold,
  baufinanzierung: baufinanzierungScaffold,
  bu: buScaffold,
  beamtenversicherung: beamtenversicherungScaffold,
  wuestenrot: wuestenrotScaffold,
  altersvorsorge: altersvorsorgeScaffold,
  motorradversicherung: motorradversicherungScaffold,
  sterbegeld: sterbegeldScaffold,
};

export type Iantrag = Instance<typeof antrag>;
export type SOAntrag = SnapshotOut<typeof antrag>;
export type SIAntrag = SnapshotIn<typeof antrag>;
