import { types, Instance } from 'mobx-state-tree';

export const agent = types.union(
  types.undefined,
  types.model({
    nr: types.string,
    oenr: types.maybe(types.string),
    registerNummer: types.maybe(types.string),
    anredeschluessel: types.string,
    name: types.string,
    vorname: types.maybeNull(types.string),
    nachname: types.string,
    email: types.string,
    street: types.string,
    postalcode: types.string,
    city: types.string,
    phone: types.maybeNull(types.string),
    contactEmail: types.maybeNull(types.string),
    url: types.string,
    portraitImageUrl: types.string,
    otb: types.boolean,
    otbUrl: types.maybeNull(types.string),
    whatsapp: types.boolean,
    whatsappNumber: types.maybeNull(types.string),
    whatsappUrl: types.maybeNull(types.string),
    directionsUrl: types.maybeNull(types.string),
    aem: types.maybe(types.boolean), // remove after next VermittlerService release
  })
);

export type Iagent = Instance<typeof agent>;
