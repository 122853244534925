import * as React from 'react';
import { IReactionDisposer } from 'mobx';
import { observer } from 'mobx-react';

import { LoadingSpinner } from '@eg/elements/LoadingSpinner';
import { StoreContext } from '../StoreContext';

import CSS from './Layout.module.scss';

interface LayoutProps {
  header: React.ReactNode | null;
  main: React.ReactNode;
  footer: React.ReactNode | null;
}

@observer
export class Layout extends React.Component<LayoutProps> {
  static contextType = StoreContext;
  //@ts-ignore
  context!: React.ContextType<typeof StoreContext>;

  disposer?: IReactionDisposer;

  pageErrorRef: HTMLElement | null = null;

  componentDidMount() {}

  componentWillUnmount() {
    this.disposer!();
  }

  render() {
    const { ui } = this.context;

    // This transfers error which occured outside of a render function to Reacts ErrorBoundary
    if (ui.error) {
      throw ui.error;
    }

    const { header, main, footer } = this.props;

    return (
      <>
        {header && (
          <div className={CSS.header} id="header">
            {header}
          </div>
        )}
        <div className={CSS.container}>
          <div role="main">{main}</div>
        </div>
        <LoadingSpinner show={ui.loadingStatus === 'LOADING'} />
        {footer && <div className="esc_container">{footer}</div>}
      </>
    );
  }
}
